import { Manager } from "socket.io-client";
import { useState, useEffect, useRef } from "react";

export default function App() {
  const managerRef = useRef();
  const socketRef = useRef();
  const [sessionId, setSessionId] = useState(null);
  const [position, setPosition] = useState(null);
  const [estimatedMinutes, setEstimatedMinutes] = useState(null);
  const startingPosition = useRef(null);
  const [lang, setLang] = useState("tc");
  const [loaded, setLoaded] = useState(false);

  const [queuePercent, setQueuePercent] = useState(40);
  const langRef = useRef("tc");

  useEffect(() => {
    let url =
      process.env.REACT_APP_QUEUEABLE_ENDPOINT || "https://faom.queueable.net";
    let sessionId = window.localStorage.getItem("sessionId");

    if (sessionId) {
      url += `?sessionId=${sessionId}`;
    }

    managerRef.current = new Manager(url);
    socketRef.current = managerRef.current.socket("/");

    socketRef.current.on("connect", () => {
      console.log("connected");
    });

    socketRef.current.io.on("reconnect", () => {
      console.log("reconnected");
      socketRef.current.emit("reconnect");
    });

    socketRef.current.on("queue", (data) => {
      setSessionId(data.sessionId);
      setPosition(data.position);
      setEstimatedMinutes(data.estimatedMinutes);

      if (!startingPosition.current) {
        startingPosition.current = data.position;
      }

      const percentage =
        40 +
        ((startingPosition.current - data.position) /
          startingPosition.current) *
          60;
      setQueuePercent(percentage);

      window.localStorage.setItem("sessionId", data.sessionId);
    });

    socketRef.current.on("move", () => {
      console.log("move");
      socketRef.current.emit("position");
    });

    socketRef.current.on("redirect", (data) => {
      setSessionId(data.sessionId);
      setPosition("0");

      const percentage = 100;
      setQueuePercent(percentage);

      console.log("redirect");

      let languageUrl = data.url;

      // original url: https://faom.momini.app/signUp/?sessionId=xxx
      // change to: https://faom.momini.app/tc/signUp/?sessionId=xxx
      if (langRef.current === "tc") {
        languageUrl = languageUrl.replace(
          "https://faom.momini.app/",
          "https://faom.momini.app/tc/"
        );
      } else if (langRef.current === "en") {
        languageUrl = languageUrl.replace(
          "https://faom.momini.app/",
          "https://faom.momini.app/en/"
        );
      }

      window.location = languageUrl;
    });

    socketRef.current.on("disconnect", () => {
      console.log("disconnected");
    });

    // get `lang` params from url
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lang");
    if (lang) {
      langRef.current = lang === "en" ? "en" : "tc";
      setLang(langRef.current);
    }

    setLoaded(true);

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  return (
    <div className="max-w-2xl mx-auto w-full min-h-screen flex flex-col justify-center items-center">
      <div className="w-full p-8 pt-0">
        <div className="mb-2 flex flex-col justify-center text-center p-10 pt-0 rounded-xl relative bg-white">
          <div className="mb-2 w-full flex items-center justify-center">
            <svg
              className="w-20 h-20 animate-spin text-[#B00831]"
              style={{
                animationDuration: "5s",
              }}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4.75V6.25"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M17.1266 6.87347L16.0659 7.93413"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M19.25 12L17.75 12"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M17.1266 17.1265L16.0659 16.0659"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M12 17.75V19.25"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M7.9342 16.0659L6.87354 17.1265"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6.25 12L4.75 12"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M7.9342 7.93413L6.87354 6.87347"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          {loaded && (
            <>
              {lang === "tc" && (
                <>
                  <p className="mb-2 text-xl">您正在排隊進行報名</p>
                  <p className="text-xl">很快就輪到您了，請稍候</p>
                </>
              )}
              {lang === "en" && (
                <>
                  <p className="mb-2 text-xl">You are in the queue</p>
                  <p className="text-xl">Please wait</p>
                </>
              )}
            </>
          )}
        </div>

        {loaded && (
          <>
            {lang === "tc" && (
              <p className="leading-8 text-center">
                您正在排隊報名「中國銀行呈獻 -
                愛心同行‧工聯福利基金慈善跑」，很快就輪到您了，請耐心等候，請勿離開此頁面或關閉程式。
              </p>
            )}

            {lang === "en" && (
              <p className="leading-8 text-center">
                You are in the queue for registration of "中國銀行呈獻 -
                愛心同行‧工聯福利基金慈善跑". Please wait patiently and do not
                leave this page or close the program.
              </p>
            )}
          </>
        )}
      </div>

      {loaded && (
        <div className="relative w-full h-3">
          <div
            className="absolute h-full bg-[#B00831] transition-all duration-1000 rounded-sm"
            style={{ width: `${queuePercent}%` }}
          ></div>
        </div>
      )}

      <div className="mt-4 py-4 leading-8 tracking-wider text-center bg-white">
        {loaded && (
          <>
            {lang === "tc" && (
              <>
                <p>
                  前面還有{" "}
                  <span className="font-bold">{position || "..."} 人</span>
                  正在等候
                </p>
                <p>
                  預計需時{" "}
                  <span className="font-bold">
                    {estimatedMinutes !== null ? (
                      estimatedMinutes ? (
                        estimatedMinutes
                      ) : (
                        <>&lt; 1</>
                      )
                    ) : (
                      "..."
                    )}{" "}
                    分鐘
                  </span>
                </p>
              </>
            )}

            {lang === "en" && (
              <>
                <p>
                  There are{" "}
                  <span className="font-bold">
                    {position || "..."}
                    {position === 1 ? " person" : " people"}
                  </span>{" "}
                  in front of you
                </p>
                <p>
                  Estimated time:{" "}
                  <span className="font-bold">
                    {estimatedMinutes !== null ? (
                      estimatedMinutes ? (
                        estimatedMinutes
                      ) : (
                        <>&lt; 1</>
                      )
                    ) : (
                      "..."
                    )}{" "}
                    {estimatedMinutes <= 1 ? "minute" : "minutes"}
                  </span>
                </p>
              </>
            )}
          </>
        )}
        <p className="text-gray-300 text-xs mt-8">{sessionId}</p>
      </div>
    </div>
  );
}
